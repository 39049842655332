import axiosClient from "../axios-client";
import { useEffect, useState } from "react";
import { Card, Table, Button, Row, Alert, Modal } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Link } from "react-router-dom";
import Spinners from "../docs/Spinners";
import { useDispatch, useSelector } from "react-redux";
import { deleteDrives, setDrives } from "../store/driveSlice";
import { setLoading } from "../store/uiSlice";

export default function GoogleDrive() {
  const [loading, setFetchLoading] = useState(false);
  const [notification, setNotification] = useState(false);
  const [notification2, setNotification2] = useState(false);
  const [show, setShow] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [fetchResult, setFetchResult] = useState(null);
  const dispatch = useDispatch();
  const files = useSelector((state) => state.drives);
  const monthNames = [
    "Ianuarie",
    "Februarie",
    "Martie",
    "Aprilie",
    "Mai",
    "Iunie",
    "Iulie",
    "August",
    "Septembrie",
    "Octombrie",
    "Noiembrie",
    "Decembrie",
  ];
  useEffect(() => {
    if (files.length === 0) {
      getFiles();
    }
  }, []);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getFiles = () => {
    dispatch(setLoading(true));
    axiosClient
      .get("/google-drive-files")
      .then(({ data }) => {
        dispatch(setDrives(data.data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const onDeleteFilePrompt = (file) => {
    setFileToDelete(file);
    handleShow();
  };

  const onFetchNewDriveDate = (drive_id) => {
    setFetchLoading(true);

    axiosClient
      .post(`/articles/${drive_id}`)
      .then(({ data }) => {
        setFetchLoading(false);
        setFetchResult(data);
        setNotification2(true);
        setTimeout(() => {
          setNotification2(false);
        }, [20000]);
      })
      .catch((error) => {
        console.error("Eroare preluare drive:", error);
      });
  };

  const confirmDeleteFile = () => {
    if (fileToDelete && fileToDelete.id) {
      dispatch(setLoading(true));

      axiosClient
        .delete(`/google-drive-files/${fileToDelete.id}`)
        .then(() => {
          dispatch(setLoading(false));
          dispatch(deleteDrives(fileToDelete.id));
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
          }, [5000]);
        })
        .catch((error) => {
          console.error("Error deleting drive link:", error);
        });
    } else {
      console.error("No drive link to delete.");
    }

    handleClose();
  };

  const listFiles = files.map((file) => (
    <tr key={file.id} style={{ verticalAlign: "middle" }}>
      <td>
        <Link to={`https://docs.google.com/spreadsheets/d/${file.drive_id}`}>
          {file.drive_id}
        </Link>
      </td>
      <td>
        {monthNames[file.month - 1]} {file.year}
      </td>
      <td>{file.last_update}</td>
      <td>
        <Row className="gx-1 gy-2">
          <Button
            onClick={() => onFetchNewDriveDate(file.drive_id)}
            style={{ marginRight: "5px" }}
            variant="primary"
            className="btn-icon"
          >
            <i className="ri-refresh-line"></i>
          </Button>
          <Link style={{ width: "auto" }} to={`/google-drive/${file.id}`}>
            <Button
              style={{ marginRight: "5px" }}
              variant="success"
              className="btn-icon"
            >
              <i className="ri-pencil-line"></i>
            </Button>
          </Link>
          <Button
            variant="danger"
            onClick={() => onDeleteFilePrompt(file)}
            className="btn-icon"
          >
            <i className="ri-delete-bin-6-line"></i>
          </Button>
        </Row>
      </td>
    </tr>
  ));

  return (
    <>
      <Header />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmare</Modal.Title>
        </Modal.Header>
        <Modal.Body>Esti sigur ca vrei sa stergi acest drive?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Anuleaza
          </Button>
          <Button variant="primary" onClick={confirmDeleteFile}>
            Confirma
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="main main-app p-3 p-lg-4">
        {notification && (
          <Alert
            variant="primary"
            className="alert-solid d-flex align-items-center mb-2"
          >
            <i className="ri-information-line"></i>
            Fisierul a fost sters cu succes.
          </Alert>
        )}
        {fetchResult &&
          fetchResult.map((element, index) => (
            <Alert
              variant="primary"
              className="alert-solid d-flex align-items-center mb-2"
            >
              <i className="ri-information-line"></i>
              <div key={index}>{element}</div>
            </Alert>
          ))}

        <br />
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Lista Fisiere</Link>
              </li>
            </ol>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Link to="/google-drive/new">
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
              >
                <i className="ri-folder-add-line fs-18 lh-1"></i>Adauga Fisier
              </Button>
            </Link>
          </div>
        </div>
        <Card>
          <Card.Body>
            <Table striped className="mb-0">
              <thead>
                <tr>
                  <th>ID Drive</th>
                  <th>Data</th>
                  <th>Ultima Preluare</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{!loading && listFiles}</tbody>
            </Table>
          </Card.Body>
        </Card>
        {loading && <Spinners />}

        <Footer />
      </div>
    </>
  );
}
