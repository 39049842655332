import { useRef, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import axiosClient from "../axios-client";
import { AuthContext } from '../store/auth-context';

export default function Signin() {
    const emailRef = useRef();
    const passRef = useRef();
    const [errors, setErrors] = useState(null);
    const {setUser,setToken, token} = AuthContext();

  if(token){
    return <Navigate to="/"/>
  }

    const handleLogin = (e) => {
        e.preventDefault();
        const email = emailRef.current.value;
        const password = passRef.current.value;
        const payload = {
          email,
          password
      }
      axiosClient.post('/login', payload)
      .then(({data}) => {
          setUser(data.user)
          setToken(data.token)
      })
      .catch(err => {
        const response = err.response;
        if(response && response.status === 422){
            let errorMessages = [];
        const serverErrors = response.data.errors; // assuming errors are in 'errors' field
        if(serverErrors) {
            Object.keys(serverErrors).forEach(key => {
                errorMessages = [...errorMessages, ...serverErrors[key]]; // this spreads out array values
            });
        }
        setErrors(errorMessages);
    }
})

  }

  return (
    <div className="page-sign">
      <Card className="card-sign">
        <Card.Header>
          <img style={{ width: '100%', padding: '0 50px'}} src="/logo.png"/>
              {errors &&
<Alert variant="danger">
   
          <div>Datele introduse sunt invalide</div>
  
</Alert>
}
        </Card.Header>
          <Card.Body>
            <Form onSubmit={handleLogin}>
              <div className="mb-4">
                <Form.Label >Email</Form.Label>
                <Form.Control type="text" placeholder="E-mail" ref={emailRef} />
              </div>
              <div className="mb-4">
                <Form.Label className="d-flex justify-content-between">Parola</Form.Label>
                <Form.Control type="password" placeholder="Parola" ref={passRef} />
              </div>
              <Button type="submit" variant="primary" className="btn-sign">Logare</Button>
            </Form>
          </Card.Body>
      </Card>
    </div>
  )
}