import { createSlice } from "@reduxjs/toolkit";

const templateSlice = createSlice({
  name: "templates",
  initialState: [],
  reducers: {
    setTemplates: (state, action) => {
      return action.payload;
    },
    addTemplates: (state, action) => {
      state.push(action.payload);
    },
    deleteTemplates: (state, action) => {
      return state.filter((template) => template.id !== action.payload);
    },
    updateTemplates: (state, action) => {
      const index = state.findIndex(
        (template) => template.id === action.payload.id
      );
      if (index !== -1) {
        state[index] = action.payload;
      }
    },
  },
});

export const { setTemplates, addTemplates, deleteTemplates, updateTemplates } =
  templateSlice.actions;
export default templateSlice.reducer;
