import React, { useRef, useState } from "react";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import axiosClient from "../axios-client";
import { AuthContext } from '../store/auth-context';

export default function Signup() {

    const nameRef = useRef();
    const emailRef = useRef();
    const passRef = useRef();
    const [errors, setErrors] = useState(null);
    const {setUser,setToken, token} = AuthContext();

  if(token){
    return <Navigate to="/"/>
  }
    const handleSubmit = (e) => {
        e.preventDefault();
        const name = nameRef.current.value;
        const email = emailRef.current.value;
        const password = passRef.current.value;
        const payload = {
          name,
          email,
          password
      }
      axiosClient.post('/register', payload)
      .then(({data}) => {
          setUser(data.user)
          setToken(data.token)
      })
      .catch(err => {
        const response = err.response;
        if(response && response.status === 422){
            let errorMessages = [];
        const serverErrors = response.data.errors; // assuming errors are in 'errors' field
        if(serverErrors) {
            Object.keys(serverErrors).forEach(key => {
                errorMessages = [...errorMessages, ...serverErrors[key]]; // this spreads out array values
            });
        }
        setErrors(errorMessages);
    }
})

  }

  return (
    <div className="page-sign">
    <Card className="card-sign">
    <Card.Header>
    <Link to="/" className="header-logo mb-4">Digital Metrics</Link>
    <Card.Title>Cont Nou</Card.Title>
    {errors &&
<Alert variant="danger">
    {errors.map((error, index) => (
          <div style={{display: 'block'}} key={index}>{error}</div>
        ))}
</Alert>
}

</Card.Header>
<Form onSubmit={handleSubmit}>
<Card.Body>
<Form.Group className="mb-3">
<Form.Label>Email</Form.Label>
<Form.Control type="text" placeholder="Scrie adresa de email" ref={emailRef} />
</Form.Group>
<Form.Group className="mb-3">
<Form.Label>Parola</Form.Label>
<Form.Control type="password" placeholder="Scrie parola" ref={passRef} />
</Form.Group>
<Form.Group className="mb-3">
<Form.Label>Nume</Form.Label>
<Form.Control type="text" placeholder="Scrie nume" ref={nameRef} />
</Form.Group>

<Button variant="primary" className="btn-sign" type="submit">Creare Cont</Button>
</Card.Body>
</Form>
<Card.Footer>
<Link to="/login">Ai deja cont? Logare</Link>
</Card.Footer>
</Card>
</div>
);
}
