import { useEffect } from "react";
import axiosClient from "../axios-client.js";
import Pusher from "pusher-js";

import { Navigate, useLocation, Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { AuthContext } from "../store/auth-context";
import { useDispatch, useSelector } from "react-redux";
import Spinners from "../docs/Spinners";
import { setLoading } from "../store/uiSlice.js";
import { addMessage, setMessages } from "../store/messageSlice";

export default function Main() {
  const { pathname } = useLocation();
  const { user, token, setUser, setToken } = AuthContext();
  const loading = useSelector((state) => state.ui.loading);
  const messages = useSelector((state) => state.messages) || [];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true));

    axiosClient.get("/user").then(({ data }) => {
      setUser(data);
      dispatch(setLoading(false));
    });
  }, []);

  useEffect(() => {
    dispatch(setLoading(true));
    axiosClient
      .get("/messages")
      .then(({ data }) => {
        dispatch(setMessages(data.data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  }, []);

  useEffect(() => {
    // Enable pusher logging - don't include this in production
    Pusher.logToConsole = false;

    const pusher = new Pusher("0b05c8cb9d5cf00bc75c", {
      cluster: "eu",
    });

    const audio = new Audio("/notificare.wav"); // Acesta este calea către fișierul dvs. audio

    const channel = pusher.subscribe("chat");
    channel.bind("message", function (data) {
      // if (data.answered_by !== user.id) {
      //   console.log("ca ching");
      //   console.log(data.answered_by);
      //   console.log(user.id);
      //   audio.play();
      // }
      dispatch(addMessage(data.messageResource));
    });
  }, []);

  const isPathMatchingOffsets = (pathname, offsets) => {
    return offsets.some((offset) => pathname.startsWith(offset));
  };

  const offsets = ["/sites/show", "/blog/show"];
  const bc = document.body.classList;

  // Verifică dacă sidebar-ul trebuie să fie offset
  if (isPathMatchingOffsets(pathname, offsets)) {
    bc.add("sidebar-offset");
  } else {
    bc.remove("sidebar-offset");
  }

  // auto close sidebar when switching pages in mobile
  bc.remove("sidebar-show");

  // scroll to top when switching pages
  window.scrollTo(0, 0);
  return (
    <>
      <Sidebar />
      {loading && <Spinners />}
      {<Outlet />}
    </>
  );
}
