import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import axiosClient from "../axios-client";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { addDrives, updateDrives } from "../store/driveSlice";
import { useDispatch } from "react-redux";
import Spinners from "../docs/Spinners";

export default function GoogleDriveForm() {
  const [notification, setNotification] = useState(false);
  const [errors, setErrors] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState({
    id: null,
    drive_id: "",
    month: String(new Date().getMonth() + 1).padStart(2, "0"),
    year: new Date().getFullYear(),
    last_update: new Date().toISOString().split("T")[0],
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axiosClient
        .get(`/google-drive-files/${id}`)
        .then(({ data }) => {
          setLoading(false);
          setFile(data);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const onSubmit = (ev) => {
    ev.preventDefault();
    if (file.id) {
      setLoading(true);
      axiosClient
        .put(`/google-drive-files/${file.id}`, file)
        .then(({ data }) => {
          setNotification(true);
          setLoading(false);
          dispatch(updateDrives(data));
          setTimeout(() => {
            setNotification(false);
          }, [5000]);
        })
        .catch((err) => {
          setLoading(false);
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors);
          }
        });
    } else {
      axiosClient
        .post("/google-drive-files", file)
        .then(({ data }) => {
          dispatch(addDrives(data));
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
          }, [5000]);
        })
        .catch((err) => {
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors);
          }
        });
    }
  };

  return (
    <>
      {loading && <Spinners />}

      <Header />
      <div className="main main-app p-14 p-lg-4">
        {notification && (
          <Alert
            variant="primary"
            className="alert-solid d-flex align-items-center mb-2"
          >
            <i className="ri-information-line"></i>
            <div>
              {" "}
              {id ? "Fisierul a fost actualizat!" : "Fisier adaugat cu succes!"}
            </div>
          </Alert>
        )}
        <h3> {id ? "Editare fisier" : "Adaugare fisier"}</h3>
        {errors && (
          <Alert variant="danger">
            {Object.keys(errors).map((key) => (
              <div key={key}>{errors[key][0]}</div>
            ))}
          </Alert>
        )}
        <Row>
          <Col md="12">
            <Card className="card-primary">
              <Card.Body>
                <Card.Text>
                  <b>Id drive</b> se va extrage din url. Ex:{" "}
                  <b>1xALvkEVyvxzXGuRqJUjaHdav1kbSvgwA7zRyHEVJuFA</b> in
                  https://docs.google.com/spreadsheets/d/
                  <b>1xALvkEVyvxzXGuRqJUjaHdav1kbSvgwA7zRyHEVJuFA</b>
                  /edit#gid=1016759389
                </Card.Text>
                <Card.Text>
                  Fisierul adaugat trebuie share-uit cu adresa:{" "}
                  <b>managedm@manage-dm-396708.iam.gserviceaccount.com</b>
                </Card.Text>
                <Card.Text>
                  Numele clientului din drive trebuie sa fie identica nu numele
                  clientului din aplicatie.
                </Card.Text>
                <Card.Text>
                  Odata adaugat fisierul, este de preferat ca ordinea liniile
                  din excel sa ramana neschimbata. Se pot adauga linii noi la
                  sfarsitul tabelului.
                </Card.Text>
                <Card.Text>
                  Ordinea coloanelor trebuie pastrata in toate fisierele ca{" "}
                  <a
                    style={{ color: "#ffffff" }}
                    href="https://docs.google.com/spreadsheets/d/1xALvkEVyvxzXGuRqJUjaHdav1kbSvgwA7zRyHEVJuFA/edit#gid=1016759389"
                  >
                    aici
                  </a>{" "}
                  (Anchor text pe coloana I, Landing Page pe Coloana J, etc.)
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card.Body className="mt-4">
          <Form onSubmit={onSubmit}>
            <div className="mb-4">
              <Form.Label>Id Drive </Form.Label>
              <Form.Control
                type="text"
                value={file.drive_id}
                onChange={(ev) =>
                  setFile({ ...file, drive_id: ev.target.value })
                }
                autoComplete="off"
              />
            </div>
            <div className="mb-4">
              <Form.Label>Luna</Form.Label>
              <Form.Control
                as="select"
                value={file.month}
                onChange={(ev) => setFile({ ...file, month: ev.target.value })}
              >
                <option value="01">Ianuarie</option>
                <option value="02">Februarie</option>
                <option value="03">Martie</option>
                <option value="04">Aprilie</option>
                <option value="05">Mai</option>
                <option value="06">Iunie</option>
                <option value="07">Iulie</option>
                <option value="08">August</option>
                <option value="09">Septembrie</option>
                <option value="10">Octombrie</option>
                <option value="11">Noiembrie</option>
                <option value="12">Decembrie</option>
              </Form.Control>
            </div>

            <div className="mb-4">
              <Form.Label>An</Form.Label>
              <Form.Control
                type="text"
                value={file.year}
                onChange={(ev) => setFile({ ...file, year: ev.target.value })}
                autoComplete="off"
              />
            </div>

            <Button type="submit" variant="primary" className="btn-sign">
              CONFIRMA
            </Button>
          </Form>
        </Card.Body>
        <Footer />
      </div>
    </>
  );
}
