import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  ListGroup,
  Row,
  Alert,
  Modal,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import axiosClient from "../axios-client.js";
import { useSelector, useDispatch } from "react-redux";
import { setClients, deleteClient } from "../store/clientSlice";
import { setLoading } from "../store/uiSlice";
export default function DefaultDash() {
  const [notification, setNotification] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  const clients = useSelector((state) => state.clients);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true));
    axiosClient
      .get("/sites")
      .then(({ data }) => {
        dispatch(setClients(data.data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  }, [dispatch]);

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  const listClients = clients.map((client) => (
    <tr key={client.id}>
      <td>{client.name}</td>
      <td style={{ width: "4%" }}>
        {client.number_of_articles} / {client.articles}
      </td>
    </tr>
  ));
  return (
    <>
      <Header />

      <div className="main main-app p-3 p-lg-4">
        {notification && (
          <Alert
            variant="primary"
            className="alert-solid d-flex align-items-center mb-2"
          >
            <i className="ri-information-line"></i>
            Clientul a fost sters cu succes.
          </Alert>
        )}
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Lista Clienti</Link>
              </li>
            </ol>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Link to="/sites/new">
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
              >
                <i className="ri-folder-add-line fs-18 lh-1"></i>Adauga Client
              </Button>
            </Link>
          </div>
        </div>

        <Card>
          <Card.Body>
            <Table striped className="mb-0">
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Articole Publicate</th>
                </tr>
              </thead>
              <tbody>{listClients}</tbody>
            </Table>
          </Card.Body>
        </Card>

        <Footer />
      </div>
    </>
  );
}
