import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import axiosClient from "../axios-client";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addTemplates, updateTemplates } from "../store/templateSlice";
import Spinners from "../docs/Spinners";

export default function MailTemplatesForm() {
  const [notification, setNotification] = useState(false);
  const [errors, setErrors] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [mailTemplate, setMailTemplate] = useState({
    id: null,
    title: "",
    content: "",
  });

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      setLoading(true);
      axiosClient
        .get(`/mail-templates/${id}`)
        .then(({ data }) => {
          setLoading(false);
          setMailTemplate(data);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const onSubmit = (ev) => {
    ev.preventDefault();
    if (mailTemplate.id) {
      setLoading(true);
      axiosClient
        .put(`/mail-templates/${mailTemplate.id}`, mailTemplate)
        .then(({ data }) => {
          //navigate('/users')
          dispatch(updateTemplates(data));
          setNotification(true);
          setLoading(false);
          setTimeout(() => {
            setNotification(false);
          }, [5000]);
        })
        .catch((err) => {
          setLoading(false);
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors);
          }
        });
    } else {
      axiosClient
        .post("/mail-templates", mailTemplate)
        .then(({ data }) => {
          //navigate('/users')
          dispatch(addTemplates(data));
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
          }, [5000]);
        })
        .catch((err) => {
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors);
          }
        });
    }
  };

  return (
    <>
      <Header />
      {loading && <Spinners />}

      <div className="main main-app p-14 p-lg-4">
        {notification && (
          <Alert
            variant="primary"
            className="alert-solid d-flex align-items-center mb-2"
          >
            <i className="ri-information-line"></i>
            <div>Template actualizate cu succes.</div>
          </Alert>
        )}
        <h3> Templateuri Mail</h3>
        {errors && (
          <Alert variant="danger">
            {Object.keys(errors).map((key) => (
              <div key={key}>{errors[key][0]}</div>
            ))}
          </Alert>
        )}

        <Card.Body>
          <Form onSubmit={onSubmit}>
            <div className="mb-4">
              <Form.Label>Nume template</Form.Label>
              <Form.Control
                type="text"
                value={mailTemplate.title}
                onChange={(ev) =>
                  setMailTemplate({ ...mailTemplate, title: ev.target.value })
                }
              />
            </div>
            <div className="mb-4">
              <Form.Label>
                Text <br />
                <i>Shortcodes: [articole-standard], [articole-premium]</i>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="10"
                value={mailTemplate.content}
                onChange={(ev) =>
                  setMailTemplate({ ...mailTemplate, content: ev.target.value })
                }
              />
            </div>
            <Button type="submit" variant="primary" className="btn-sign">
              SALVEAZA
            </Button>
          </Form>
        </Card.Body>
        <Footer />
      </div>
    </>
  );
}
