import { createSlice } from "@reduxjs/toolkit";

const clientSlice = createSlice({
  name: "clients",
  initialState: [],
  reducers: {
    setClients: (state, action) => {
      return action.payload;
    },
    addClient: (state, action) => {
      state.push(action.payload);
    },
    deleteClient: (state, action) => {
      return state.filter((client) => client.id !== action.payload);
    },
    updateClient: (state, action) => {
      const index = state.findIndex(
        (client) => client.id === action.payload.id
      );
      if (index !== -1) {
        state[index] = action.payload;
      }
    },
  },
});

export const { setClients, addClient, deleteClient, updateClient } =
  clientSlice.actions;
export default clientSlice.reducer;
