import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "users",
  initialState: [],
  reducers: {
    setUsers: (state, action) => {
      return action.payload;
    },
    addUsers: (state, action) => {
      state.push(action.payload);
    },
    deleteUsers: (state, action) => {
      return state.filter((user) => user.id !== action.payload);
    },
    updateUsers: (state, action) => {
      const index = state.findIndex((user) => user.id === action.payload.id);
      if (index !== -1) {
        state[index] = action.payload;
      }
    },
  },
});

export const { setUsers, addUsers, deleteUsers, updateUsers } =
  userSlice.actions;
export default userSlice.reducer;
