import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Alert, Button, Card, Col, Form, Modal, Table } from "react-bootstrap";
import axiosClient from "../axios-client";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { setUsers } from "../store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { addClient, updateClient } from "../store/clientSlice";
import Spinners from "../docs/Spinners";

const KeywordRow = ({ data, updateData }) => {
  return (
    <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
      <Form.Control
        type="text"
        placeholder="Keyword"
        value={data.keyword}
        onChange={(e) => updateData("keyword", e.target.value)}
      />
      <Form.Control
        type="text"
        placeholder="Meta Title"
        value={data.metaTitle}
        onChange={(e) => updateData("meta_title", e.target.value)}
      />
      <Form.Control
        type="text"
        placeholder="Landing"
        value={data.landing}
        onChange={(e) => updateData("landing", e.target.value)}
      />
      <Form.Control
        type="text"
        placeholder="Volum"
        value={data.volume}
        onChange={(e) => updateData("volume", e.target.value)}
      />
    </div>
  );
};

export default function UserForm() {
  const { id } = useParams();
  const [kwToDelete, setKwToDelete] = useState(null);

  const [oldKeywords, setOldKeywords] = useState([]);
  const [keywords, setKeywords] = useState([
    { keyword: "", meta_title: "", landing: "", volume: "0", client_id: id },
  ]);

  const addKeywordRow = () => {
    setKeywords([
      ...keywords,
      { keyword: "", meta_title: "", landing: "", volume: "0", client_id: id },
    ]);
  };

  const updateKeywordData = (index, field, value) => {
    const newKeywords = [...keywords];
    newKeywords[index][field] = value;
    setKeywords(newKeywords);
  };
  const users = useSelector((state) => state.users);
  const [adminUsers, setAdminUsers] = useState([]); // New state for admin users
  const [show, setShow] = useState(false);

  const [notification, setNotification] = useState(false);
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();
  const [client, setClient] = useState({
    id: null,
    name: "",
    url: "",
    email: "",
    client_name: "",
    appellation: "",
    resp_tech: "",
    resp_content: "",
    articles: "",
  });
  const [dataLoaded, setDataLoaded] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    if (id) {
      axiosClient
        .get(`/sites/${id}`)
        .then(({ data }) => {
          setLoading(false);
          setClient(data);
          console.log(data);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    axiosClient
      .get(`/keywords/show/${id}`)
      .then(({ data }) => {
        setOldKeywords(data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (users.length === 0) {
      getUsers();
    } else {
      // Filter admin users from the users state
      const filteredAdminUsers = users.filter((user) => user.admin == "Staff");
      setAdminUsers(filteredAdminUsers);
    }
  }, [users]);

  const getUsers = () => {
    setLoading(true);
    axiosClient
      .get("/users")
      .then(({ data }) => {
        dispatch(setUsers(data.data));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onSubmit = (ev) => {
    ev.preventDefault();
    if (client.id) {
      setLoading(true);
      axiosClient
        .put(`/sites/${client.id}`, client)
        .then(({ data }) => {
          //navigate('/users')
          dispatch(updateClient(data));
          setErrors(null);
          setLoading(false);
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
          }, [5000]);
        })
        .catch((err) => {
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors);
            setLoading(false);
          }
        });
    } else {
      axiosClient
        .post("/sites", client)
        .then(({ data }) => {
          //navigate('/users')
          dispatch(addClient(data));
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
          }, [5000]);
        })
        .catch((err) => {
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors);
          }
        });
    }
  };

  const onSubmitKeywords = (ev) => {
    ev.preventDefault();
    setLoading(true);
    console.log(keywords);
    axiosClient
      .post("/keywords", { keywords: keywords })
      .then(({ data }) => {
        setNotification(true);
        setTimeout(() => {
          setNotification(false);
        }, [5000]);
        setLoading(false);
        setOldKeywords([...oldKeywords, ...keywords]);
        setKeywords([
          {
            keyword: "",
            meta_title: "",
            landing: "",
            volume: "0",
            client_id: id,
          },
        ]);
      })
      .catch((err) => {
        const response = err.response;
        if (response && response.status === 422) {
          setErrors(response.data.errors);
        }
        setLoading(false);
      });
  };

  const onDeleteKwPrompt = (kw) => {
    setKwToDelete(kw);
    handleShow();
  };

  const confirmDeleteKw = () => {
    if (kwToDelete && kwToDelete.id) {
      //   dispatch(setLoading(true));
      axiosClient
        .delete(`/keywords/${kwToDelete.id}`)
        .then(() => {
          const updatedKeywords = oldKeywords.filter(
            (kw) => kw.id !== kwToDelete.id
          );
          setOldKeywords(updatedKeywords);
          setNotification(true);
          //     dispatch(setLoading(false));
          setTimeout(() => {
            setNotification(false);
          }, [5000]);
        })
        .catch((error) => {
          //    dispatch(setLoading(false));
          console.error("Error deleting user:", error);
        });
    } else {
      console.error("No kw to delete or kw ID is undefined.");
    }

    handleClose();
  };

  const listKw = oldKeywords.map((kw) => (
    <tr key={kw.id}>
      <td>{kw.keyword}</td>
      <td>{kw.meta_title}</td>
      <td>{kw.landing}</td>
      <td>{kw.volume}</td>
      <td>
        <Button
          variant="danger"
          onClick={() => onDeleteKwPrompt(kw)}
          className="btn-icon"
        >
          <i className="ri-delete-bin-6-line"></i>
        </Button>{" "}
      </td>
    </tr>
  ));
  return (
    <>
      {loading && <Spinners />}

      <Header />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmare</Modal.Title>
        </Modal.Header>
        <Modal.Body>Esti sigur ca vrei sa stergi acest cuvant?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Anuleaza
          </Button>
          <Button variant="primary" onClick={confirmDeleteKw}>
            Confirma
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="main main-app p-14 p-lg-4">
        {notification && (
          <Alert
            variant="primary"
            className="alert-solid d-flex align-items-center mb-2"
          >
            <i className="ri-information-line"></i>
            <div>
              {id
                ? "Actualizare cu succes!"
                : "Clientul a fost adaugat cu succes!"}
            </div>
          </Alert>
        )}
        <h3> {id ? `Editare client: ${client.name}` : "Adaugare Client"}</h3>
        {errors && (
          <Alert variant="danger">
            {Object.keys(errors).map((key) => (
              <div key={key}>{errors[key][0]}</div>
            ))}
          </Alert>
        )}

        <Card.Body>
          <Form onSubmit={onSubmit}>
            <div className="mb-4">
              <Form.Label>Nume Site</Form.Label>
              <Form.Control
                type="text"
                value={client.name}
                onChange={(ev) =>
                  setClient({ ...client, name: ev.target.value })
                }
                autoComplete="off"
              />
            </div>
            <div className="mb-4">
              <Form.Label>Nume & Prenume</Form.Label>
              <Form.Control
                type="text"
                value={client.client_name}
                onChange={(ev) =>
                  setClient({ ...client, client_name: ev.target.value })
                }
                autoComplete="off"
              />
            </div>

            <div className="mb-4">
              <Form.Label>Adresare</Form.Label>
              <Form.Select
                value={client.appellation}
                onChange={(ev) =>
                  setClient({ ...client, appellation: ev.target.value })
                }
              >
                <option value="">Selecteaza</option>
                <option key="1" value="domnule">
                  Domn
                </option>
                <option key="2" value="doamna">
                  Doamna
                </option>
              </Form.Select>
            </div>

            <div className="mb-4">
              <Form.Label>Url</Form.Label>
              <Form.Control
                type="text"
                value={client.url}
                onChange={(ev) =>
                  setClient({ ...client, url: ev.target.value })
                }
                autoComplete="off"
              />
            </div>
            <div className="mb-4">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                value={client.email}
                onChange={(ev) =>
                  setClient({ ...client, email: ev.target.value })
                }
                autoComplete="off"
              />
            </div>
            <div className="mb-4">
              <Form.Label>Numar Articole</Form.Label>
              <Form.Control
                type="text"
                value={client.articles}
                onChange={(ev) =>
                  setClient({ ...client, articles: ev.target.value })
                }
                autoComplete="off"
              />
            </div>
            <div className="mb-4">
              <Form.Label>Responsabil Tehnic</Form.Label>
              <Form.Select
                value={client.resp_tech}
                onChange={(ev) =>
                  setClient({ ...client, resp_tech: ev.target.value })
                }
              >
                <option value="">Selecteaza</option>
                {adminUsers.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="mb-4">
              <Form.Label>Responsabil Content</Form.Label>
              <Form.Select
                value={client.resp_content}
                onChange={(ev) =>
                  setClient({ ...client, resp_content: ev.target.value })
                }
              >
                <option value="">Selecteaza</option>
                {adminUsers.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <Button type="submit" variant="primary" className="btn-sign">
              CONFIRMA
            </Button>
          </Form>
        </Card.Body>

        <Card.Body>
          <h3 style={{ marginTop: "30px" }}> Keywords</h3>

          <Form>
            {keywords.map((data, index) => (
              <KeywordRow
                key={index}
                data={data}
                updateData={(field, value) =>
                  updateKeywordData(index, field, value)
                }
              />
            ))}
            <Button style={{ marginRight: "30px" }} onClick={addKeywordRow}>
              +Keyword
            </Button>
            <Button
              type="submit"
              variant="primary"
              className="btn-sign"
              onClick={onSubmitKeywords}
            >
              SALVEAZA
            </Button>
          </Form>
          <br />
          <Card>
            <Card.Body>
              <Table striped className="mb-0">
                <thead>
                  <tr>
                    <th>Keyword</th>
                    <th>Meta Title</th>
                    <th>Landing</th>
                    <th>Volum</th>
                  </tr>
                </thead>
                <tbody>{listKw}</tbody>
              </Table>
            </Card.Body>
          </Card>
        </Card.Body>
        <Footer />
      </div>
    </>
  );
}
