import React, { useEffect } from "react";
import Prism from "prismjs";
import { Card, Container, Nav, Spinner, Button } from "react-bootstrap";
import Footer from "../layouts/Footer";

export default function Spinners() {

  return (
    <>
        <Container>
<div style={{
  position: 'fixed', 
  top: '50%', 
  left: '50%', 
  transform: 'translate(-50%, -50%)', 
  textAlign: 'center'
}}>
  <div className="spinner-border" style={{width: '3rem', height: '3rem'}} role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
  <p>Se încarcă...</p>
</div>


        </Container>

    </>
  );
}