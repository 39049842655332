import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import axiosClient from "../axios-client";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { addDrives, updateDrives } from "../store/driveSlice";
import { useDispatch } from "react-redux";
import Spinners from "../docs/Spinners";
import ReactDatePicker from "react-datepicker";

export default function BlogArticleForm() {
  const [notification, setNotification] = useState(false);
  const [errors, setErrors] = useState(null);
  const { id, clientID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [article, setArticle] = useState({
    id: null,
    title: "",
    keyword: "",
    volume: "0",
    drive: "",
    date: "",
    title_approved: "NU",
    articles_approved: "NU",
    link: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axiosClient
        .get(`/blog-articles/${id}`)
        .then(({ data }) => {
          console.log(data);
          setLoading(false);
          setArticle(data);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);
  let formattedDate = "";
  if (article.bruteDate) {
    const localDate = new Date(article.bruteDate);
    const localTime = new Date(
      localDate.getTime() - localDate.getTimezoneOffset() * 60000
    );
    formattedDate = localTime.toISOString().split("T")[0];
  }
  const updatedArticle = {
    ...article,
    date: formattedDate,
    bruteDate: formattedDate,
  };
  const articleToSubmit = {
    ...article,
    client_id: clientID,
    date: formattedDate,
  };

  const onSubmit = (ev) => {
    ev.preventDefault();
    const resetArticleState = () => {
      setArticle({
        id: null,
        title: "",
        keyword: "",
        volume: "0",
        drive: "",
        date: "",
        title_approved: "NU",
        articles_approved: "NU",
        link: "",
      });
    };
    if (article.id) {
      setLoading(true);
      axiosClient
        .put(`/blog-articles/${article.id}`, updatedArticle)
        .then(({ data }) => {
          setNotification(true);
          setLoading(false);
          //dispatch(updateDrives(data));
          setTimeout(() => {
            setNotification(false);
          }, [5000]);
        })
        .catch((err) => {
          setLoading(false);
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors);
          }
        });
    } else {
      axiosClient
        .post("/blog-articles", articleToSubmit)
        .then(({ data }) => {
          //dispatch(addDrives(data));
          resetArticleState();
          setNotification(true);
          setTimeout(() => {
            setNotification(false);
          }, [5000]);
        })
        .catch((err) => {
          const response = err.response;
          if (response && response.status === 422) {
            setErrors(response.data.errors);
          }
        });
    }
  };

  return (
    <>
      {loading && <Spinners />}

      <Header />
      <div className="main main-app p-14 p-lg-4">
        {notification && (
          <Alert
            variant="primary"
            className="alert-solid d-flex align-items-center mb-2"
          >
            <i className="ri-information-line"></i>
            <div>
              {" "}
              {id ? "Articolul a fost actualizat!" : "Adaugat cu succes!"}
            </div>
          </Alert>
        )}
        <h3> {id ? "Editare articol" : "Adaugare articol"}</h3>
        {errors && (
          <Alert variant="danger">
            {Object.keys(errors).map((key) => (
              <div key={key}>{errors[key][0]}</div>
            ))}
          </Alert>
        )}
        <Card.Body className="mt-4">
          <Form onSubmit={onSubmit}>
            <div className="mb-4">
              <Form.Label>Titlu </Form.Label>
              <Form.Control
                type="text"
                value={article.title}
                onChange={(ev) =>
                  setArticle({ ...article, title: ev.target.value })
                }
                autoComplete="off"
              />
            </div>
            <div className="mb-4">
              <Form.Label>Cuvant Cheie </Form.Label>
              <Form.Control
                type="text"
                value={article.keyword}
                onChange={(ev) =>
                  setArticle({ ...article, keyword: ev.target.value })
                }
                autoComplete="off"
              />
            </div>

            <div className="mb-4">
              <Form.Label>Volum Cautari </Form.Label>
              <Form.Control
                type="text"
                value={article.volume}
                onChange={(ev) =>
                  setArticle({ ...article, volume: ev.target.value })
                }
                autoComplete="off"
              />
            </div>

            <div className="mb-4">
              <Form.Label>Drive </Form.Label>
              <Form.Control
                type="text"
                value={article.drive}
                onChange={(ev) =>
                  setArticle({ ...article, drive: ev.target.value })
                }
                autoComplete="off"
              />
            </div>
            <div className="mb-4">
              <Form.Label>Link Articol </Form.Label>
              <Form.Control
                type="text"
                value={article.link}
                onChange={(ev) =>
                  setArticle({ ...article, link: ev.target.value })
                }
                autoComplete="off"
              />
            </div>
            <div className="mb-4">
              <Form.Label>Data </Form.Label>

              <ReactDatePicker
                selected={
                  article.bruteDate ? new Date(article.bruteDate) : null
                }
                onChange={(date) => {
                  if (date) {
                    setArticle({
                      ...article,
                      bruteDate: date,
                      date: date,
                    });
                  } else {
                    // Handle the case where the date is cleared or invalid
                    setArticle({ ...article, bruteDate: "", date: "" });
                  }
                }}
                autoComplete="off"
                className="form-control"
              />
            </div>
            <div className="mb-4">
              <Form.Label>Titlu Aprobat</Form.Label>
              <Form.Control
                as="select"
                value={article.title_approved}
                onChange={(ev) =>
                  setArticle({ ...article, title_approved: ev.target.value })
                }
              >
                <option value="DA">DA</option>
                <option value="NU">NU</option>
              </Form.Control>
            </div>
            <div className="mb-4">
              <Form.Label>Articol Aprobat</Form.Label>
              <Form.Control
                as="select"
                value={article.articles_approved}
                onChange={(ev) =>
                  setArticle({ ...article, articles_approved: ev.target.value })
                }
              >
                <option value="DA">DA</option>
                <option value="NU">NU</option>
              </Form.Control>
            </div>

            <Button type="submit" variant="primary" className="btn-sign">
              CONFIRMA
            </Button>
          </Form>
        </Card.Body>
        <Footer />
      </div>
    </>
  );
}
