import axiosClient from "../axios-client";
import { useEffect, useState } from "react";
import { Card, Table, Button, Row, Alert, Modal } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Link } from "react-router-dom";
import Spinners from "../docs/Spinners";
import { useDispatch, useSelector } from "react-redux";
import { deleteUsers, setUsers } from "../store/userSlice";
import { setLoading } from "../store/uiSlice";
export default function Users() {
  const [notification, setNotification] = useState(false);
  const [show, setShow] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const users = useSelector((state) => state.users);
  useEffect(() => {
    if (users.length === 0) {
      getUsers();
    }
  }, []);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getUsers = () => {
    dispatch(setLoading(true));
    axiosClient
      .get("/users")
      .then(({ data }) => {
        dispatch(setUsers(data.data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };

  const onDeleteUserPrompt = (user) => {
    setUserToDelete(user);
    handleShow();
  };

  const confirmDeleteUser = () => {
    if (userToDelete && userToDelete.id) {
      dispatch(setLoading(true));

      axiosClient
        .delete(`/users/${userToDelete.id}`)
        .then(() => {
          dispatch(deleteUsers(userToDelete.id));
          setNotification(true);
          dispatch(setLoading(false));
          setTimeout(() => {
            setNotification(false);
          }, [5000]);
        })
        .catch((error) => {
          dispatch(setLoading(false));
          console.error("Error deleting user:", error);
        });
    } else {
      console.error("No user to delete or user ID is undefined.");
    }

    handleClose();
  };

  const listUsers = users.map((user) => (
    <tr key={user.id} style={{ verticalAlign: "middle" }}>
      <td>{user.id}</td>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>{user.admin}</td>
      <td>
        <Row className="gx-1 gy-2">
          <Link style={{ width: "auto" }} to={`/users/${user.id}`}>
            <Button
              style={{ marginRight: "5px" }}
              variant="success"
              className="btn-icon"
            >
              <i className="ri-pencil-line"></i>
            </Button>
          </Link>
          <Button
            variant="danger"
            onClick={() => onDeleteUserPrompt(user)}
            className="btn-icon"
          >
            <i className="ri-delete-bin-6-line"></i>
          </Button>{" "}
        </Row>
      </td>
    </tr>
  ));
  return (
    <>
      <Header />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmare</Modal.Title>
        </Modal.Header>
        <Modal.Body>Esti sigur ca vrei sa stergi acest user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Anuleaza
          </Button>
          <Button variant="primary" onClick={confirmDeleteUser}>
            Confirma
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="main main-app p-3 p-lg-4">
        {notification && (
          <Alert
            variant="primary"
            className="alert-solid d-flex align-items-center mb-2"
          >
            <i className="ri-information-line"></i>
            Userul a fost sters cu succes.
          </Alert>
        )}

        <br />
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Lista Useri</Link>
              </li>
            </ol>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Link to="/users/new">
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
              >
                <i className="ri-folder-add-line fs-18 lh-1"></i>Adauga User
              </Button>
            </Link>
          </div>
        </div>
        <Card>
          <Card.Body>
            <Table striped className="mb-0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nume</th>
                  <th>Email</th>
                  <th>Rol</th>
                  <th>Actiuni</th>
                </tr>
              </thead>
              <tbody>{listUsers}</tbody>
            </Table>
          </Card.Body>
        </Card>

        <Footer />
      </div>
    </>
  );
}
