import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import PerfectScrollbar from "react-perfect-scrollbar";
import Avatar from "../components/Avatar";
import DOMPurify from "dompurify";
import img14 from "../assets/img/user.png";

import axiosClient from "../axios-client.js";
import { AuthContext } from "../store/auth-context";
import { useSelector, useDispatch } from "react-redux";
import { setClients, deleteClient } from "../store/clientSlice";
import { setLoading } from "../store/uiSlice";
import { addMessage, setMessages } from "../store/messageSlice";
export default function Chat() {
  const [currentChatWindow, setCurrentChatWindow] = useState({
    id: "",
    name: "",
  });
  const clients = useSelector((state) => state.clients);
  const allMessages = useSelector((state) => state.messages) || [];
  const { user } = AuthContext();
  const endOfMessagesRef = useRef(null);
  const [newMessage, setNewMessage] = useState({
    message_text: "",
    client: "",
    answered_by: "",
  });
  const [filteredMessages, setFilteredMessages] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    document.body.classList.add("page-app");
    return () => {
      document.body.classList.remove("page-app");
    };
  }, []);

  useEffect(() => {
    const clientMessages = allMessages.filter(
      (msg) => msg.client === currentChatWindow.id
    );
    setFilteredMessages(clientMessages);
  }, [currentChatWindow.id, allMessages]);

  useEffect(() => {
    if (clients.length === 0) {
      dispatch(setLoading(true));
      axiosClient
        .get("/sites")
        .then(({ data }) => {
          dispatch(setClients(data.data));
          dispatch(setLoading(false));
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    }
  }, []);

  useEffect(() => {
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({
        block: "end",
      });
    }
  }, [filteredMessages]);

  const getUnreadMessagesCount = (clientId) => {
    return allMessages.filter(
      (msg) =>
        msg.client === clientId &&
        msg.answered_by !== user.id &&
        msg.readed === 0
    ).length;
  };
  const onSubmit = (ev) => {
    ev.preventDefault();

    axiosClient
      .post("/messages", newMessage)
      .then(({ data }) => {
        setNewMessage((prevState) => ({
          ...prevState,
          message_text: "",
        }));
        // Update the currentChatMessages state directly
        //  dispatch(addMessage(data));
        //    setTimeout(() => {}, [5000]);
      })
      .catch((err) => {
        const response = err.response;
      });
  };

  const groupByDate = (filteredMessages) => {
    return filteredMessages.reduce((acc, message) => {
      const date = new Date(message.date).toDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {});
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  const transformMessagesToGroup = (filteredMessages) => {
    const groupedMessages = groupByDate(filteredMessages);
    const sortedDates = Object.keys(groupedMessages).sort(
      (a, b) => new Date(a) - new Date(b)
    );
    return sortedDates.map((date) => {
      const displayDate = isToday(new Date(date))
        ? "Astazi"
        : new Date(date).toLocaleDateString("ro-RO", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          });
      return {
        date: displayDate,
        items: groupedMessages[date].map((message) => ({
          reverse:
            user.id === message.answered_by || (user.admin && message.is_staff),
          messages: [
            {
              text: message.message_text,
              time: new Date(message.date).toLocaleTimeString(), // Extracting time from the date
            },
          ],
        })),
      };
    });
  };
  const chatWindowHandler = (chat_id, client_name) => {
    setCurrentChatWindow({
      id: chat_id,
      name: client_name,
    });
    markAsReaded(chat_id);
  };

  const getSortedClients = () => {
    return clients.slice().sort((a, b) => {
      const unreadA = getUnreadMessagesCount(a.id);
      const unreadB = getUnreadMessagesCount(b.id);
      return unreadB - unreadA; // Clients with more unread messages will appear at the top.
    });
  };

  const markAsReaded = (clientId) => {
    axiosClient
      .put(`/messages/mark-as-read/${clientId}`)
      .then((response) => {
        // Actualizați orice stare sau UI dacă este necesar
      })
      .catch((error) => {
        console.error("Error marking messages as read:", error);
      });
    // Update the messages in the Redux store
    const updatedMessages = allMessages.map((msg) => {
      if (msg.client === clientId && msg.answered_by !== user.id) {
        return { ...msg, readed: 1 };
      }
      return msg;
    });

    dispatch(setMessages(updatedMessages));
  };

  const messageGroup = transformMessagesToGroup(filteredMessages);

  const [chatActive, setChatActive] = useState(1);
  const [msgShow, setMsgShow] = useState(false);

  // Toggle chat option in each item
  const navToggle = (e) => {
    e.target.closest(".row").classList.toggle("nav-show");
  };
  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className={"chat-panel" + (msgShow ? " msg-show" : "")}>
          <div className="chat-sidebar">
            <div className="sidebar-header">
              <h6 className="sidebar-title me-auto">Chat</h6>
            </div>

            <PerfectScrollbar className="sidebar-body">
              <label className="sidebar-label mb-2">Conversatii</label>

              <div className="chat-group mb-4">
                {getSortedClients().map((channel, index) => (
                  <div
                    className="chat-item"
                    key={index}
                    onClick={() => chatWindowHandler(channel.id, channel.name)}
                  >
                    {getUnreadMessagesCount(channel.id) > 0 && (
                      <div className="avatar">
                        <span className="avatar-initial avatar-red">
                          {getUnreadMessagesCount(channel.id)}
                        </span>
                      </div>
                    )}
                    {getUnreadMessagesCount(channel.id) === 0 && (
                      <div className="avatar">
                        <span className="avatar-initial">0</span>
                      </div>
                    )}
                    <div className="chat-item-body">
                      <h6>{channel.name}</h6>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(channel.desc),
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </PerfectScrollbar>
          </div>
          <div className="chat-body">
            <div className="chat-body-header">
              <div className="chat-item">
                {currentChatWindow.name && <Avatar img={img14} />}
                <div className="chat-item-body">
                  <h6 className="mb-1">
                    {currentChatWindow.name
                      ? currentChatWindow.name
                      : "Alege un site pentru a deschide o conversatia"}
                  </h6>
                </div>
              </div>
            </div>
            {currentChatWindow.name && (
              <PerfectScrollbar
                className="chat-body-content"
                onClick={() => markAsReaded(currentChatWindow.id)}
              >
                {messageGroup.map((msgroup, index) => (
                  <React.Fragment key={index}>
                    <div className="divider">
                      <span>{msgroup.date}</span>
                    </div>
                    {msgroup.items.map((item, ind) => (
                      <div
                        key={ind}
                        className={
                          "msg-item" + (item.reverse ? " reverse" : "")
                        }
                      >
                        {item.avatar && <Avatar img={img14} />}
                        <div className="msg-body">
                          {item.messages.map((message, i) => (
                            <Row key={i} className="gx-3 row-cols-auto">
                              <Col>
                                <div
                                  className="msg-bubble"
                                  onMouseOver={navToggle}
                                  onMouseLeave={navToggle}
                                >
                                  {message.text}
                                  <span>{message.time}</span>
                                </div>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
                <div ref={endOfMessagesRef} />
              </PerfectScrollbar>
            )}
            {currentChatWindow.name && (
              <Form onSubmit={onSubmit} className="chat-body-footer">
                <div className="msg-box">
                  <Form.Control
                    type="text"
                    placeholder="Scrie..."
                    onClick={() => markAsReaded(currentChatWindow.id)}
                    value={newMessage.message_text}
                    onChange={(ev) =>
                      setNewMessage({
                        answered_by: user.id,
                        client: currentChatWindow.id,
                        message_text: ev.target.value,
                        readed: 0,
                      })
                    }
                  />
                  <button type="submit" className="msg-send">
                    <i className="ri-send-plane-2-line"></i>
                  </button>
                </div>
              </Form>
            )}
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
}
