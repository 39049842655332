import { configureStore } from "@reduxjs/toolkit";
import clientReducer from "./clientSlice";
import uiReducer from "./uiSlice";
import userReducer from "./userSlice";
import templateSlice from "./templateSlice";
import driveSlice from "./driveSlice";
import messageSlice from "./messageSlice";
import blogPostsSlice from "./blogPostsSlice";

const store = configureStore({
  reducer: {
    clients: clientReducer,
    ui: uiReducer,
    users: userReducer,
    templates: templateSlice,
    drives: driveSlice,
    messages: messageSlice,
    blogPosts: blogPostsSlice,
  },
});

export default store;
