import axiosClient from "../axios-client";
import { useEffect, useState } from "react";
import {
  Card,
  Table,
  Button,
  Row,
  Alert,
  ListGroup,
  Modal,
} from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Link } from "react-router-dom";
import Spinners from "../docs/Spinners";
import { useDispatch, useSelector } from "react-redux";
import { deleteTemplates, setTemplates } from "../store/templateSlice";
import { setLoading } from "../store/uiSlice";

export default function MailTemplates() {
  const [notification, setNotification] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [templateToDelete, setTemplateToDelete] = useState(null);
  const dispatch = useDispatch();
  const mailTemplates = useSelector((state) => state.templates);
  useEffect(() => {
    if (mailTemplates.length === 0) {
      getMailTemplates();
    }
  }, []);

  const getMailTemplates = () => {
    dispatch(setLoading(true));
    axiosClient
      .get("/mail-templates")
      .then(({ data }) => {
        dispatch(setTemplates(data.data));
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const onDeleteMailTemplatePrompt = (mailTemplate) => {
    setTemplateToDelete(mailTemplate);
    handleShowModal();
  };

  const confirmDeleteMailTemplate = () => {
    if (templateToDelete) {
      dispatch(setLoading(true));
      axiosClient.delete(`/mail-templates/${templateToDelete.id}`).then(() => {
        const updatedTemplates = mailTemplates.filter(
          (template) => template.id !== templateToDelete.id
        );
        dispatch(deleteTemplates(templateToDelete.id));
        dispatch(setLoading(false));

        setNotification(true);
        setTimeout(() => {
          setNotification(false);
        }, 5000);
      });
      handleCloseModal();
    }
  };

  const listMailTemplates = mailTemplates.map((mailTemplate, ind) => (
    <ListGroup.Item
      key={ind}
      as="li"
      className="px-0 d-flex align-items-center gap-2"
    >
      <div>
        <Link to="#">
          <h6 className="mb-0">{mailTemplate.title}</h6>
        </Link>
      </div>
      <div className="ms-auto text-end">
        <Row className="gx-1 gy-2">
          <Link
            style={{ width: "auto" }}
            to={`/mail-templates/${mailTemplate.id}`}
          >
            <Button
              style={{ marginRight: "5px" }}
              variant="success"
              className="btn-icon"
            >
              <i className="ri-pencil-line"></i>
            </Button>
          </Link>
          <Button
            variant="danger"
            onClick={() => onDeleteMailTemplatePrompt(mailTemplate)}
            className="btn-icon"
          >
            <i className="ri-delete-bin-6-line"></i>
          </Button>
        </Row>
      </div>
    </ListGroup.Item>
  ));
  return (
    <>
      <Header />

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmare</Modal.Title>
        </Modal.Header>
        <Modal.Body>Esti sigur ca vrei să stergi acest template?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Anuleaza
          </Button>
          <Button variant="primary" onClick={confirmDeleteMailTemplate}>
            Confirma
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="main main-app p-3 p-lg-4">
        {notification && (
          <Alert
            variant="primary"
            className="alert-solid d-flex align-items-center mb-2"
          >
            <i className="ri-information-line"></i>
            Template-ul a fost sters cu succes.
          </Alert>
        )}

        <br />
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Lista Templateuri Mail</Link>
              </li>
            </ol>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Link to="/mail-templates/new">
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
              >
                <i className="ri-folder-add-line fs-18 lh-1"></i>Adauga Template
                Mail
              </Button>
            </Link>
          </div>
        </div>

        <Card className="card-one">
          <Card.Body>
            <ListGroup as="ul" className="list-group-one">
              {listMailTemplates}
            </ListGroup>
          </Card.Body>
        </Card>

        <Footer />
      </div>
    </>
  );
}
