import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  ListGroup,
  Row,
  Alert,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import axiosClient from "../axios-client.js";
import { useSelector, useDispatch } from "react-redux";
import { setClients, deleteClient } from "../store/clientSlice";
import { setLoading } from "../store/uiSlice";
export default function DefaultDash() {
  const [notification, setNotification] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  const clients = useSelector((state) => state.clients);

  const dispatch = useDispatch();

  useEffect(() => {
    if (clients.length === 0) {
      dispatch(setLoading(true));
      axiosClient
        .get("/sites")
        .then(({ data }) => {
          dispatch(setClients(data.data));
          dispatch(setLoading(false));
        })
        .catch(() => {
          dispatch(setLoading(false));
        });
    }
  }, []);

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  const onDeleteClientPrompt = (client) => {
    setClientToDelete(client);
    handleShowModal();
  };

  const confirmDeleteClient = () => {
    if (clientToDelete) {
      dispatch(setLoading(true));

      axiosClient.delete(`/sites/${clientToDelete.id}`).then(() => {
        dispatch(deleteClient(clientToDelete.id));
        setNotification(true);
        dispatch(setLoading(false));
        setTimeout(() => {
          setNotification(false);
        }, 5000);
      });
      handleCloseModal();
    }
  };

  return (
    <>
      <Header />
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmare</Modal.Title>
        </Modal.Header>
        <Modal.Body>Esti sigur ca vrei sa stergi acest client?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Anuleaza
          </Button>
          <Button variant="primary" onClick={confirmDeleteClient}>
            Confirma
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="main main-app p-3 p-lg-4">
        {notification && (
          <Alert
            variant="primary"
            className="alert-solid d-flex align-items-center mb-2"
          >
            <i className="ri-information-line"></i>
            Clientul a fost sters cu succes.
          </Alert>
        )}
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Lista Clienti</Link>
              </li>
            </ol>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Link to="/sites/new">
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
              >
                <i className="ri-folder-add-line fs-18 lh-1"></i>Adauga Client
              </Button>
            </Link>
          </div>
        </div>

        <Col sm="12" xl="12">
          <Card className="card-one">
            <Card.Body>
              <ListGroup as="ul" className="list-group-one">
                {clients.map((client, ind) => (
                  <ListGroup.Item
                    key={ind}
                    as="li"
                    className="px-0 d-flex align-items-center gap-2"
                  >
                    <div>
                      <Link to={"/sites/show/" + client.id}>
                        <h6 className="mb-0">{client.name}</h6>
                      </Link>
                      <small>{client.url}</small>
                    </div>
                    <div className="ms-auto text-end">
                      <Row className="gx-1 gy-2">
                        <Link
                          style={{ width: "auto" }}
                          to={`/sites/${client.id}`}
                        >
                          <Button
                            style={{ marginRight: "5px" }}
                            variant="success"
                            className="btn-icon"
                          >
                            <i className="ri-pencil-line"></i>
                          </Button>
                        </Link>
                        <Button
                          variant="danger"
                          onClick={() => onDeleteClientPrompt(client)}
                          className="btn-icon"
                        >
                          <i className="ri-delete-bin-6-line"></i>
                        </Button>
                      </Row>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>

        <Footer />
      </div>
    </>
  );
}
