import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from "./layouts/Main";
import NotFound from "./pages/NotFound";
import Pusher from "pusher-js";

import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";

import { AuthContext } from "./store/auth-context";

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";

// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  const { token } = AuthContext();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={token ? <Main /> : <Navigate to="/login" replace />}
        >
          {protectedRoutes.map((route, index) => {
            return (
              <Route path={route.path} element={route.element} key={index} />
            );
          })}
        </Route>

        {publicRoutes.map((route, index) => {
          return (
            <Route path={route.path} element={route.element} key={index} />
          );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
