import React from "react";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import userAvatar from "../assets/img/user.png";
import notification from "../data/Notification";
import { AuthContext } from "../store/auth-context";
import { useSelector } from "react-redux";
import { useEffect } from "react";
export default function Header() {
  const { user } = AuthContext();
  const allMessages = useSelector((state) => state.messages) || [];
  const unreadMessages = allMessages.filter(
    (msg) => msg.readed === 0 && msg.answered_by !== user.id
  );

  const location = useLocation();

  // useEffect(() => {
  //   // Verificați dacă suntem pe ruta dorită
  //   if (location.pathname.includes("/sites/show/")) {
  //     // Ascundeți meniul
  //     document.body.classList.add("sidebar-hide");
  //     document.body.classList.remove("sidebar-show");
  //   } else {
  //     // Asigurați-vă că meniul este afișat pe alte rute
  //     document.body.classList.remove("sidebar-hide");
  //     document.body.classList.add("sidebar-show");
  //   }
  // }, [location]); // Dependență de location pentru a reacționa la schimbările de rută

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-link"
    >
      {children}
    </Link>
  ));

  const toggleSidebar = (e) => {
    e.preventDefault();
    let isOffset = document.body.classList.contains("sidebar-offset");
    if (isOffset) {
      document.body.classList.toggle("sidebar-show");
    } else {
      if (window.matchMedia("(max-width: 991px)").matches) {
        document.body.classList.toggle("sidebar-show");
      } else {
        document.body.classList.toggle("sidebar-hide");
      }
    }
  };

  function NotificationList() {
    const notiList = notification.map((item, key) => {
      return (
        <li className="list-group-item" key={key}>
          <div
            className={item.status === "online" ? "avatar online" : "avatar"}
          >
            {item.avatar}
          </div>
          <div className="list-group-body">
            <p>{item.text}</p>
            <span>{item.date}</span>
          </div>
        </li>
      );
    });

    return <ul className="list-group">{notiList}</ul>;
  }

  return (
    <div className="header-main px-3 px-lg-4">
      <Link onClick={toggleSidebar} className="menu-link me-3 me-lg-4">
        <i className="ri-menu-2-fill"></i>
      </Link>

      <div className="form-search me-auto">
        <input type="text" className="form-control" placeholder="Cauta" />
        <i className="ri-search-line"></i>
      </div>

      {unreadMessages.length > 0 && (
        <Dropdown className="dropdown-notification ms-3 ms-xl-4" align="end">
          <Link to="/chat" class="alert alert-solid alert-danger" role="alert">
            <b>Ai {unreadMessages.length} mesaje necitite</b>
          </Link>
        </Dropdown>
      )}

      <Dropdown className="dropdown-profile ms-3 ms-xl-4" align="end">
        <Dropdown.Toggle as={CustomToggle}>
          <div className="avatar online">
            <img src={userAvatar} alt="" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="mt-10-f">
          <div className="dropdown-menu-body">
            <div className="avatar avatar-xl online mb-3">
              <img src={userAvatar} alt="" />
            </div>
            <h5 className="mb-1 text-dark fw-semibold">{user.name}</h5>
            <nav className="nav">
              <Link to="">
                <i className="ri-user-settings-line"></i> Setari
              </Link>
              <Link to="">
                <i className="ri-logout-box-r-line"></i> Deconectare
              </Link>
            </nav>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
