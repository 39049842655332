import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { Alert, Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import axiosClient from "../axios-client";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Spinners from "../docs/Spinners";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { startOfMonth, endOfMonth, format } from "date-fns";
import ConfirmModal from "../components/ConfirmModal";

export default function ClientShow() {
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [notification, setNotification] = useState(false);
  const [errors, setErrors] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getLinks();
  }, []);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [selectedDateRange, setSelectedDateRange] = useState(dateRange);

  const formattedStartDate = format(dateRange[0].startDate, "yyyy-MM-dd");
  const formattedEndDate = format(dateRange[0].endDate, "yyyy-MM-dd");
  const currentDate = format(new Date(), "yyyy-MM-dd");

  const [client, setClient] = useState({
    id: null,
    name: "",
    url: "",
    email: "",
    client_name: "",
    appellation: "",
    resp_tech: "",
    resp_content: "",
    last_rep_content: "",
    last_rep_pb: "",
    unt_last_rep_content: "",
    unt_last_rep_pb: "",
  });

  const handleDateChange = (item) => {
    setSelectedDateRange([item.selection]);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleDateConfirm = () => {
    const startDate = format(selectedDateRange[0].startDate, "yyyy-MM-dd");
    const endDate = format(selectedDateRange[0].endDate, "yyyy-MM-dd");
    setShowDatePicker(false); // Adăugați această linie pentru a închide fereastra cu calendarul
    getLinks(startDate, endDate);
  };
  const startOfCurrentMonth = format(startOfMonth(new Date()), "yyyy-MM-dd");
  const endOfCurrentMonth = format(endOfMonth(new Date()), "yyyy-MM-dd");
  const getLinks = (
    dateStart = startOfCurrentMonth,
    dateEnd = endOfCurrentMonth
  ) => {
    setLoading(true);
    axiosClient
      .get(`/articles/date/${id}/${dateStart}/${dateEnd}`)
      .then(({ data }) => {
        setLinks(data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const sendMail = () => {
    axiosClient
      .post("/send-lb-mail", { client_id: id })
      .then((response) => {
        console.log(response.data.message);
        setEmailSuccess(true); // Actualizați starea pentru succes
        setEmailError(""); // Resetați mesajul de eroare
        setTimeout(() => {
          setEmailSuccess(false); // Resetați notificarea de succes după 5 secunde
        }, 5000);
      })
      .catch((error) => {
        setEmailError("Eroare la trimiterea e-mailului"); // Setează mesajul de eroare
        setEmailSuccess(false); // Asigurați-vă că starea de succes este falsă
      });
    handleCloseModal();
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      axiosClient
        .get(`/sites/${id}`)
        .then(({ data }) => {
          setClient(data);
          console.log(data);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]); // Dependința [id] asigură că efectul se execută doar la schimbarea ID-ului

  const listClients = links.map((client) => (
    <tr key={client.id} style={{ verticalAlign: "middle" }}>
      <td>
        <Link to={client.site_link}>{client.site_link}</Link>
      </td>
      <td style={{ textAlign: "center" }}>
        <Link to={client.drive_link}>
          <i className="ri-draft-line fs-3"></i>
        </Link>
      </td>
      <td>{client.title}</td>
      <td>{client.anchor_text}</td>
      <td>
        <Link to={client.landing_page}>{client.landing_page}</Link>
      </td>
      <td>{client.anchor_text2}</td>
      <td>
        <Link to={client.landing_page2}>{client.landing_page2}</Link>
      </td>
      <td>{client.keyword}</td>
      <td style={{ textAlign: "center" }}>{client.is_premium}</td>
      <td style={{ fontSize: "10px" }}>{client.publish_date}</td>
    </tr>
  ));

  // Check if the last report date is within the current month
  const isLastRepThisMonth = () => {
    if (!client.unt_last_rep_pb) return false;
    const [year, month] = client.unt_last_rep_pb.split("-");
    const currentYear = new Date().getFullYear();
    const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0");
    return year == currentYear && month == currentMonth;
  };
  return (
    <>
      <Header />

      <ConfirmModal
        showModal={showModal}
        handleClose={handleCloseModal}
        handleConfirm={sendMail}
        title="Confirmare"
        body="Esti sigur ca vrei sa trimiti catre client?"
      />

      <div className="main main-app p-3 p-lg-4">
        {emailSuccess && (
          <Alert
            variant="success"
            className="alert-solid d-flex align-items-center mb-2"
          >
            E-mailul a fost trimis cu succes.
          </Alert>
        )}
        {emailError && (
          <Alert
            variant="danger"
            className="alert-solid d-flex align-items-center mb-2"
          >
            {emailError}
          </Alert>
        )}
        {notification && (
          <Alert
            variant="primary"
            className="alert-solid d-flex align-items-center mb-2"
          >
            <i className="ri-information-line"></i>
            Userul a fost sters cu succes.
          </Alert>
        )}

        <br />
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <h3>{client.name}</h3>
                <Link to="/">Publishing Plan</Link>
              </li>
            </ol>
            <p style={{ color: "#5c5757", fontSize: "12px" }}>
              Ultimul raport trimis: <b>{client.last_rep_pb}</b>
            </p>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
            >
              Publishing Plan
            </Button>
            <Link to={`/blog/show/${id}`}>
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
              >
                Articole Blog
              </Button>
            </Link>
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
            >
              SEO Onpage
            </Button>

            <Button
              onClick={handleShowModal}
              variant={isLastRepThisMonth() ? "danger" : "success"}
              className="d-flex align-items-center gap-2"
            >
              <i className="ri-mail-line fs-18 lh-1"></i>Trimite Catre Client
            </Button>

            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                setShowDatePicker(true);
              }}
            >
              <Button
                variant=""
                className="btn-white d-flex align-items-center gap-2"
              >
                <i className="ri-calendar-line fs-18 lh-1"></i>Alege data
              </Button>
            </Link>
          </div>
        </div>

        <Card>
          <Card.Body>
            {showDatePicker && (
              <>
                <DateRangePicker
                  onChange={handleDateChange}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectedDateRange}
                  direction="horizontal"
                  rangeColors={["#3d91ff"]}
                />
                <Button
                  style={{ marginTop: "5px", marginLeft: "10px" }}
                  onClick={handleDateConfirm}
                >
                  CONFIRMARE
                </Button>
              </>
            )}

            <Table striped className="mb-0 publish-table">
              <thead>
                <tr>
                  <th style={{ width: "25%" }}>Link</th>
                  <th style={{ width: "50px" }}>Drive</th>
                  <th style={{ width: "15%" }}>Titlu Articol</th>
                  <th style={{ width: "10%" }}>Ancora 1</th>
                  <th style={{ width: "20%" }}>Link 1</th>
                  <th style={{ width: "10%" }}>Ancora 2</th>
                  <th style={{ width: "20%" }}>Link 2</th>
                  <th style={{ width: "10%" }}>KW</th>
                  <th style={{ width: "50px" }}>Tip</th>
                  <th style={{ width: "62px" }}>Data</th>
                </tr>
              </thead>
              <tbody>{!loading && listClients}</tbody>
            </Table>
          </Card.Body>
        </Card>
        {loading && <Spinners />}

        <Footer />
      </div>
    </>
  );
}
