import { createSlice } from "@reduxjs/toolkit";

const driveSlice = createSlice({
  name: "drives",
  initialState: [],
  reducers: {
    setDrives: (state, action) => {
      return action.payload;
    },
    addDrives: (state, action) => {
      state.push(action.payload);
    },
    deleteDrives: (state, action) => {
      return state.filter((drive) => drive.id !== action.payload);
    },
    updateDrives: (state, action) => {
      const index = state.findIndex((drive) => drive.id === action.payload.id);
      if (index !== -1) {
        state[index] = action.payload;
      }
    },
  },
});

export const { setDrives, addDrives, deleteDrives, updateDrives } =
  driveSlice.actions;
export default driveSlice.reducer;
