import { createSlice } from "@reduxjs/toolkit";

const blogPostsSlice = createSlice({
  name: "blogPosts",
  initialState: [],
  reducers: {
    setblogPosts: (state, action) => {
      return action.payload;
    },
    addblogPosts: (state, action) => {
      state.push(action.payload);
    },
    deleteblogPosts: (state, action) => {
      return state.filter((blogPost) => blogPost.id !== action.payload);
    },
    updateblogPosts: (state, action) => {
      const index = state.findIndex(
        (blogPost) => blogPost.id === action.payload.id
      );
      if (index !== -1) {
        state[index] = action.payload;
      }
    },
  },
});

export const { setblogPosts, addblogPosts, deleteblogPosts, updateblogPosts } =
  blogPostsSlice.actions;
export default blogPostsSlice.reducer;
